<template>
  <div id="poi-info" v-if="currentPoi" :class="{fullscreen: isCurrentPoiFullscreen}">
    <div class="poi-navigation">
      <template v-if="currentPoiMedias.length > 1">
        <el-button icon="el-icon-arrow-left" type="text" class="icon-poi-arrow-left" @click="$refs.carousel.advancePage('backward')"/>
        <el-button icon="el-icon-arrow-right" type="text" class="icon-poi-arrow-right" @click="$refs.carousel.advancePage()"/>
      </template>
      <el-button icon="el-icon-close" type="text" class="icon-poi-close" @click="showPoi()"/>
    </div>
    <carousel
      class="poi-carousel"
      ref="carousel"
      v-if="currentPoiMedias.length"
      :perPage="1"
      :paginationColor="'#f0f0f0'"
      @mounted="$refs.carousel.render()"
      @pageChange="onCarouselPageChange($event)">
      <slide
        v-for="media of currentPoiMedias"
        v-bind:key="media.image_url">
        <div class="image">
          <img v-if="isCurrentPoiFullscreen" :src="media.image_url"/>
          <img v-else :src="media.image_url | asset(340, 256, false)"/>
          <div class="legend" v-if="tr(media.legend)">{{ tr(media.legend) }}</div>
        </div>
      </slide>
    </carousel>
    <div class="poi-info-content">
      <div
        v-for="entry of poiInfo"
        v-bind:key="entry.key"
        :class="['poi-entry-row', 'p-' + entry.name + '-row', entry.cssClass]">
        <div :class="['poi-entry-title', 'p-' + entry.name + '-title']">{{ entry.title }}</div>
        <div :class="['poi-entry-content', 'p-' + entry.name + '-content']" v-html="entry.content"/>
      </div>
    </div>
    <div class="poi-carousel-dots" v-if="currentPoiMedias.length > 1">
      <div
        v-for="(media, index) in currentPoiMedias"
        v-bind:key="media.image_url"
        @mousedown="$refs.carousel.goToPage(index)"
        :class="['poi-carousel-dot', index === currentPoiMediaIndex ? 'poi-carousel-dot-selected' : '']">
        <template v-if="index === currentPoiMediaIndex"><font-awesome-icon size="xs" :icon="['fas', 'circle']" /></template>
        <template v-else><font-awesome-icon size="xs" :icon="['far', 'circle']" /></template>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { Carousel, Slide } from 'vue-carousel'
import { asset } from '../utils'

export default {
  name: 'PublicMap',
  props: ['poi'],
  components: {
    Carousel,
    Slide
  },

  data () {
    return {
      minZoom: undefined,
      maxZoom: undefined,
      zoom: undefined,
      isTileLayer: false,
      isIndoor: false,
      sourceUrl: true,
      crs: null,
      bounds: null,
      imageBounds: null,
      pois: [],
      loadedPoisIds: [],
      displayedPois: [],
      geojsons: [],
      displayedGeojsons: [],
      center: undefined,
      currentPoi: null,
      poiInfo: [],
      currentMap: null,
      currentZoom: 0,
      showCategories: true,
      zoomSnap: 1,
      loadingPoisOffset: 0,
      loadingPoisLimit: 50,
      categories: [],
      categoriesKeys: [],
      hashNavigation: '',
      hashBounds: null,
      hashPoi: null,
      hashIgnoreChanges: true,
      hashPushNext: false,
      hashFromLoad: null,
      hashFromLoadPrepared: false,
      hashFromLoadPoiLoaded: false,
      hashFromLoadCompleted: false,
      categoriesProps: {
        children: 'children',
        label: 'label'
      }
    }
  },

  methods: {
    onCarouselPageChange (ev) {
      if (isNaN(ev)) return
      if (this.ignoreCarouselPageChange === true) return
      this.currentPoiMediaIndex = parseInt(ev)
      this.refreshDisplayedPoiFields()
    },
    selectImage (index) {
      this.currentPoiMediaIndex = index
      this.refreshDisplayedPoiFields()
    },
    async refreshDisplayedPoiFields (ignoreCarouselPageChange) {
      if (ignoreCarouselPageChange) {
        this.ignoreCarouselPageChange = true
        setTimeout(() => {
          this.ignoreCarouselPageChange = false
        }, 100)
      }

      let poiInfo = []

      // default fields from the app
      let layout = this.currentAppState.current_layout

      // if a media is selected, and it has a layout id, use it instead
      if (this.currentPoiMedias.length) {
        let media = this.currentPoiMedias[this.currentPoiMediaIndex]
        if (media && media.layout_id) {
          await this.$store.dispatch('ensureLayouts')
          layout = this.$store.state.layouts.find(x => x.id === media.layout_id)
        }
      }

      // fill the poi with all the translated_fields and default_fields
      let fields = layout.displayed_fields.poi_default_translated_fields
      let isFirstListItem = true
      for (const [fieldKey, field_] of Object.entries(fields)) {
        // FIXME: displayed_fields contain a copy, not the real one
        // find the updated version
        let field = this.currentAppState.fields.poi_default_translated_fields.find(
          x => x.field_name === field_.field_name)
        if (!field) {
          continue
        }
        if (field.type === 'string' || field.type === 'longstring') {
          let content = this.tf(this.currentPoi, field.field_name)
          if (content && content.length > 1) {
            let info = {
              key: fieldKey,
              name: field.field_name,
              title: this.tr(field.displayed_name),
              cssClass: 'poi-entry-style-' + (field.style || 'default'),
              content: content
            }
            if (field.style === 'list-item') {
              if (isFirstListItem) {
                isFirstListItem = false
                info.cssClass += ' poi-entry-style-list-item-first'
              }
            }
            poiInfo.push(info)
          }
        }
      }
      this.poiInfo = poiInfo
    },
    showPoi (poi) {
      if (poi === undefined) {
        this.currentPoi = null
        this.poiInfo = []
        return
      }
      if (poi.content_fields.linked_360_map) {
        this.$router.push({name: 'public-map-360', params: {
          appID: this.$route.params.appID,
          mapID: poi.content_fields.linked_360_map
        }, query: this.$route.query});
        return;
      }

      this.currentPoiMediaIndex = 0
      this.currentPoi = cloneDeep(poi)
      this.refreshDisplayedPoiFields()
    },
  },

  computed: {
    currentAppState () {
      return this.$store.state.app
    },
    currentMapState () {
      return this.$store.state.currentMap
    },
    isCurrentPoiFullscreen () {
      if (this.currentPoi === undefined) return false
      if (this.$route.query.fullscreenPois === '1') return true
      if (this.currentPoi.content_fields.fullscreen_item === true) return true
      return false
    },
    currentPoiMedias () {
      if (this.currentPoi === undefined) return []
      return this.currentPoi.media.filter(entry => {
        let platform = (entry.platform || [])
        if (platform.length === 0) return true
        if (platform.indexOf('web') !== -1) return true
      })
    }
  },

  watch: {
    'poi': {
      handler (poi) {
        this.showPoi(poi)
      },
    },
  },

  mounted () {
    if (this.poi) {
      this.showPoi(this.poi)
    }
  }
}
</script>

<style scoped>
.image {
  width: 100%;
  display: block;
}

#poi-info {
  position: fixed;
  background: #f0f0f0;
  color: #030303;
  width: auto;
  top: 20px;
  left: 20px;
  z-index: 2000;
  max-width: 400px;
  max-height: 95%;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 768px) {
  #poi-info {
    position: fixed;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    max-width: none;
  }
}

#poi-info .poi-entry-title {
  font-weight: bold;
  padding: 2px 0;
  text-transform: capitalize;
}

#poi-info .poi-entry-content {
  margin-bottom: 10px;
}

#poi-info .image {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

#poi-info .legend {
  height: auto;
  width: 100%;
  background-color: white;
  color: black;
  bottom: 0px;
  position: fixed;
  padding: 10px;
}

#poi-info .poi-info-content {
  overflow-y: auto;
  padding: 20px;
}

.poi-navigation > button {
  font-size: 36px;
  color: black;
}

.poi-navigation {
  order: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .icon-poi-close,
  .icon-poi-arrow-right,
  .icon-poi-arrow-left {
    font-size: 26px;
    margin-right: 10px;
    margin-left: 0px;
  }

  .icon-poi-arrow-right::after,
  .icon-poi-arrow-left::after {
    border-right: 1px solid black;
    height: 18px;
    display: block;
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: -10px;
    content: " ";
  }
  .icon-poi-close {
    font-size: 30px;
    margin-right: 14px;
  }
}

#poi-info .poi-entry-content {
  white-space: pre-wrap;
}

/* PBA move in a custom field */
#poi-info {
  right: 0;
  left: auto;
  top: 0;
  bottom: 0;
  background-color: white;
  max-height: 100%;
  font-size: 14px;
  width: 365px;
}

#poi-info .image {
  background-color: white;
}

#poi-info .icon-poi-close {
  font-size: 36px;
}

#poi-info .poi-entry-content {
  text-align: right;
  width: 100%;
  align-self: flex-end;
  margin-bottom: 0;
}

#poi-info .p-title-title,
#poi-info .p-text1-title,
#poi-info .p-text2-title {
  display: none;
}

#poi-info .p-title-content {
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
}

#poi-info .poi-entry-row {
  display: flex;
  border-bottom: 1px solid #706f6f;
}

#poi-info .poi-entry-row .poi-entry-content {
  padding: 7px;
  padding-right: 0;
}
#poi-info .poi-entry-row .poi-entry-title {
  padding: 7px;
  padding-left: 0;
}

#poi-info .poi-entry-row .p-title-content,
#poi-info .poi-entry-row .p-text1-content,
#poi-info .poi-entry-row .p-text2-content {
  text-align: left;
  margin-bottom: 10px;
  padding: 0;
}

#poi-info .p-title-row,
#poi-info .p-text1-row,
#poi-info .p-text2-row {
  border-bottom: 0px solid transparent;
}

.poi-info-slide-enter-active, .poi-info-slide-leave-active {
  transition: all .5s ease;
  transform: translateX(0);
}

.poi-info-slide-enter, .poi-info-slide-leave-to {
  transform: translateX(100%);
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.icon-image {
    width: 24px;
    vertical-align: middle;
}

#poi-info .poi-carousel-dots {
  display: none;
  cursor: pointer;
}

</style>

<style>
.VueCarousel-pagination {
  margin-top: -20px !important;
}
</style>
