<template>
  <div v-loading="loading" class="no-margin fullheight">
    <div class="fullheight" id="panorama"
      @mouseup="onMouseUp"
      @touchend="onTouchEnd"/>
    <PoiSidebar :poi="currentPoi" />
    <el-alert
      v-if="!loading && !sourceUrl"
      title="Aucune carte disponible"
      type="error"
      center/>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'
import _debounce from 'lodash.debounce'
import { asset } from '../utils'
import PoiSidebar from './PoiSidebar'

export default {
  name: 'PublicMap360',
  props: ['appID', 'mapID'],
  components: {
    PoiSidebar
  },

  data () {
    return {
      loading: true,
      isTileLayer: false,
      sourceUrl: true,
      currentMap: null,
      currentPoi: null,
      poiInfo: [],
      baseUrl: null,
      pannellumConfig: {},
      viewer: null,
      loadedPoisIds: [],
      hotSpots: [],
    }
  },

  methods: {
    async prepareLoading () {
      // having a async loading allow leaflet widget to be reconstructed
      // from scratch, in case CRS changes, as it's not supported in
      // leaflet in live
      this.loading = true
    },

    async prepare () {
      await this.prepareLoading()
      this.currentMap = cloneDeep(this.$store.state.currentMap)
      this.sourceUrl = this.getSourceUrl(this.currentMap.source_url)
      if (this.currentMap.source_url.indexOf('{z}') !== -1) {
        this.isTileLayer = true
      }
      this.map_default_content_fields = this.$store.state.app.fields.map_default_content_fields
      this.map_default_translated_fields = this.$store.state.app.fields.map_default_translated_fields
    },

    showPoi (poi) {
      if (poi === undefined) {
        this.currentPoi = null
        this.poiInfo = []
        return
      }
      if (poi.content_fields.linked_360_map) {
        this.$router.push({name: 'public-map-360', params: {
          appID: this.$route.params.appID,
          mapID: poi.content_fields.linked_360_map
        }, query: this.$route.query});
        return;
      }

      this.currentPoiMediaIndex = 0
      this.currentPoi = cloneDeep(poi)
      this.refreshDisplayedPoiFields(true)
      this.refreshRouterHashPoi()
    },

    pushGeojson (poi, position) {
      return;
    },

    getPoiTitle (poi) {
      // default fields from the app
      let layout = this.currentAppState.current_layout
      // fill the poi with all the translated_fields and default_fields
      let fields = layout.displayed_fields.poi_default_translated_fields
      for (const [fieldKey, field_] of Object.entries(fields)) {
        // FIXME: displayed_fields contain a copy, not the real one
        // find the updated version
        let field = this.currentAppState.fields.poi_default_translated_fields.find(
          x => x.field_name === field_.field_name)
        if (!field) {
          continue
        }
        if (field.type === 'string' || field.type === 'longstring') {
          let content = this.tf(poi, field.field_name)
          if (content && content.length > 1) {
            return content
          }
        }
      }
    },

    pushPoi (poi, position) {
      let latlng = position.coordinates
      let icon = undefined
      let ax = 0
      let ay = 0
      let iwidth = 48
      let iheight = 48
      let anchor = 'center'
      if (poi.icon_url) {
        let md = poi.icon_metadata
        let useCustomSize = md.use_custom
        let assetUrl

        // determinate the ratio
        let oWidth = md.original_width || 48
        let oHeight = md.original_height || 48
        let ratio = oWidth / oHeight

        // determinate the icon width/height
        if (useCustomSize === undefined) {
          useCustomSize = true
        }

        if (useCustomSize) {
          iwidth = md.width || oWidth
          iheight = md.height || oHeight
          assetUrl = asset(poi.icon_url, iwidth, iheight)
        } else {
          let boxSize = md.box_size
          if (boxSize === undefined) {
            boxSize = 48
          }
          iwidth = boxSize
          iheight = boxSize
          if (iwidth > iheight) {
            iwidth = iheight * ratio
          } else {
            iheight = iwidth / ratio
          }
          assetUrl = asset(poi.icon_url, boxSize)
        }

        // determinate the anchor
        anchor = md.anchor || 'center'
        if (anchor === 'center') {
          ax = iwidth / 2
          ay = iheight / 2
        } else if (anchor === 'top') {
          ax = iwidth / 2
          ay = 0
        } else if (anchor === 'top-left') {
          ax = 0
          ay = 0
        } else if (anchor === 'top-right') {
          ax = iwidth
          ay = 0
        } else if (anchor === 'left') {
          ax = 0
          ay = iheight / 2
        } else if (anchor === 'right') {
          ax = iwidth
          ay = iheight / 2
        } else if (anchor === 'bottom-right') {
          ax = 0
          ay = iheight
        } else if (anchor === 'bottom-right') {
          ax = iwidth
          ay = iheight / 2
        } else {
          ax = iwidth / 2
          ay = iheight
        }

        //icon = new L.divIcon({
        //  html: '<img src="' + assetUrl + '" class="divicon"/>',
        //  iconSize: [iwidth, iheight],
        //  iconAnchor: [ax, ay]
        //})
        icon = assetUrl
      }
      //let newPoi = {
      //  id: poi.id,
      //  draggable: false,
      //  data: poi,
      //  latlng: L.latLng(latlng[1], latlng[0]),
      //  icon: icon
      //}

      // calculate pitch and yaw from x/y, and x/y max of the map
      const xMax = this.currentMap.bounds.max_lon || 4096
      const yMax = this.currentMap.bounds.max_lat || 2048
      const xMin = this.currentMap.bounds.min_lon || 0
      const yMin = this.currentMap.bounds.min_lat || 0

      const pitch = (latlng[1] - yMin) / (yMax - yMin) * 180 - 90
      const yaw = (latlng[0] - xMin) / (xMax - xMin) * 360 - 180
      const text = this.getPoiTitle(poi)
      console.log(text, pitch, yaw)

      const hotspot = { pitch, yaw }

      hotspot.clickHandlerArgs = { poi }
      hotspot.clickHandlerFunc = (_, args) => {
        this.showPoi(args.poi)
      }

      hotspot.createTooltipArgs = { icon, text }
      hotspot.createTooltipFunc = (div, args) => {
        console.log('hotspot createTooltipFunc', div, args)
        if (args.icon) {
          const img = document.createElement('img')
          img.style.position = 'absolute'
          img.style.marginLeft = `${-ax}px`
          img.style.marginTop = `${-ay}px`
          img.style.width = `${iwidth}px`
          img.style.height = `${iheight}px`
          img.style.maxWidth = 'none'
          img.src = args.icon
          div.appendChild(img)
        }
      }

      this.hotSpots.push(hotspot)
    },

    loadPoi (poi) {
      // if the user is logged, state is indicated in the output.
      // otherwise it's a public API request, therefore state doesn't show
      if (poi.state !== undefined) {
        // user logged, state can be checked
        if (poi.state !== 'published') {
          return null
        }
      }

      const position = poi.indoor_position
      if (!position) return
      if (position.type === 'Point') {
        this.loadedPoisIds.push(poi.id)
        return this.pushPoi(poi, position)
      } else if (position.type === 'Polygon') {
        this.loadedPoisIds.push(poi.id)
        return this.pushGeojson(poi, position)
      }
      return null
    },

    loadPois () {
      for (let poi of this.$store.state.pois) {
        if (this.loadedPoisIds.indexOf(poi.id) !== -1) continue
        this.loadPoi(poi)
      }
    },

    loadPanorama () {
      let options = {
          type: 'multires',
          autoLoad: true,
          showControls: true,
          draggable: true,
          autoRotate: true,
          orientationOnByDefault: true,
          hfov: 60,
          basePath: this.baseUrl
      }
      options.multiRes = this.pannellumConfig.multiRes
      options.hotSpots = this.hotSpots
      console.log(this.hotSpots)
      this.viewer = window.pannellum.viewer('panorama', options)
      this.loading = false
    },

    onMouseUp () {
      this.debounceRotate()
    },
    onTouchEnd () {
      this.debounceRotate()
    },
    debounceRotate: _debounce(function () {
      this.viewer.startOrientation()
    }, 3000)

  },

  computed: {
    currentAppState () {
      return this.$store.state.app
    }
  },

  async mounted () {
    let script = document.createElement('script')
    script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js')
    document.head.appendChild(script)

    let css = document.createElement('link')
    css.setAttribute('rel', 'stylesheet')
    css.setAttribute('href', 'https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css')
    document.head.appendChild(css)

    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('ensureMap', {
      appID: this.appID,
      mapID: this.mapID
    })

    await this.$store.dispatch('loadCategories', {
      appID: this.appID
    })
    await this.$store.dispatch('loadPois', {
      appID: this.appID,
      mapID: this.mapID,
      limit: 100,
    })
    await this.prepare()
    this.loadPois()

    this.baseUrl = `https://s3.eu-central-1.amazonaws.com/pebblo-mbtiles/${this.mapID}/panorama`
    const instance = axios.create({})
    instance.defaults.headers.common = {}
    instance.get(`${this.baseUrl}/config.json`)
      .then((response) => {
        this.pannellumConfig = response.data
        this.loadPanorama()
      })
  },

  beforeDestroy () {
    this.viewer.destroy()
  }

}
</script>

<style scoped>
#panorama.fullheight {
  overflow-x: hidden;
}
.no-margin {
  margin: -20px;
}
.poi-info-slide-enter-active, .poi-info-slide-leave-active {
  transition: all .5s ease;
  transform: translateX(0);
}
.poi-info-slide-enter, .poi-info-slide-leave-to {
  transform: translateX(100%);
}
</style>
<style>
.pnlm-ui .pnlm-about-msg {
  display: none !important;
}
.pnlm-ui .pnlm-orientation-button {
  display: none !important;
}
.pnlm-container .pnlm-grab {
  height: 100%;
  position: absolute;
}
.pnlm-container .pnlm-render-container {
  height: 100%;
}
.pnlm-iconShow span {
  visibility: visible !important;
  background: none !important;
  margin-top: -100% !important;
}
.pnlm-hotspot.pnlm-sprite {
  background-image: none !important;
}
.pnlm-hotspot {
  width: auto !important;
  height: auto !important;
  border-radius: none !important;
}
.pnlm-hotspot-base:hover {
  background: none !important;
}
.pnlm-hotspot-base.pnlm-pointer:hover {
  --stroke-pos: 1.35px;
  --stroke-neg: -1px;
  --stroke-color: #758e41;
  filter: drop-shadow(var(--stroke-pos) 0 0 var(--stroke-color)) drop-shadow(var(--stroke-neg) 0 var(--stroke-color)) drop-shadow(0 var(--stroke-pos) 0 var(--stroke-color)) drop-shadow(0 var(--stroke-neg) 0 var(--stroke-color)) drop-shadow(var(--stroke-pos) var(--stroke-pos) 0 var(--stroke-color)) drop-shadow(var(--stroke-pos) var(--stroke-neg) 0 var(--stroke-color)) drop-shadow(var(--stroke-neg) var(--stroke-pos) 0 var(--stroke-color)) drop-shadow(var(--stroke-neg) var(--stroke-neg) 0 var(--stroke-color))
}

</style>
